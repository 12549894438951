import React from "react";
import { Layout } from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";

function Loader() {
  let color = "#A9A9A9";

  return (
    <Layout
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <ScaleLoader color={color} size={80} />
    </Layout>
  );
}

export default Loader;
