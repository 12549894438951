import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Loader from "../components/loader/index";
// import CareGiverRoutes from "./careGiverProfileRoutes";

const LandingPage = lazy(() => import("../pages/landingpage"));
const Contactus = lazy(() => import("../pages/contact-us"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));
const TermsOfUse = lazy(() => import("../pages/term-of-use"));
const ApplyToProgram = lazy(() => import("../pages/apply-to-program"));
const About = lazy(() => import("../pages/About/index"));
const Reviews = lazy(() => import("../pages/Reviews/Index"));

export default function AppRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/contact-us" element={<Contactus />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/term-of-use" element={<TermsOfUse />} />
        <Route exact path="/apply-to-program" element={<ApplyToProgram />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/reviews" element={<Reviews />} />
      </Routes>
    </React.Suspense>
  );
}
